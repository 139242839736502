import React from 'react'
import { Form } from '@unform/web'
import Select from 'react-select'
import {
  Container, Row, Col, Button,
} from 'reactstrap'
import Input from '../components/Input'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import ExplanationSection from '../components/ExplanationSection'

import '../scss/page-talk-to-us.scss'

function handleSubmit(data, { reset }) {
  console.log(data)

  reset()
}

const selectOptions = [
  {
    value: '',
    label: 'Escolha aqui o seu debate grátis',
    isDisabled: true,
  },
  {
    value: 'Bedtime Stories with the Obamas - Monday July 13th at 16:30h',
    label: 'Bedtime Stories with the Obamas - Monday July 13th at 16:30h',
  },
  {
    value: 'Bedtime Stories with the Obamas - Monday July 13th at 19h',
    label: 'Bedtime Stories with the Obamas - Monday July 13th at 19h',
  },
  {
    value: 'Stop Hate for Profit - Tuesday July 14th at 17h',
    label: 'Stop Hate for Profit - Tuesday July 14th at 17h',
  },
  {
    value: 'A New Step for LGBT Rights - Tuesday July 14th at 17h',
    label: 'A New Step for LGBT Rights - Tuesday July 14th at 17h',
  },
  {
    value: 'Bedtime Stories with the Obamas - Wednesday July 15th at 11h',
    label: 'Bedtime Stories with the Obamas - Wednesday July 15th at 11h',
  },
  {
    value: 'Stop Hate for Profit - Wednesday July 15th at 17h',
    label: 'Stop Hate for Profit - Wednesday July 15th at 17h',
  },
  {
    value: 'A New Step for LGBT Rights - Thursday July 16th at 18h',
    label: 'A New Step for LGBT Rights - Thursday July 16th at 18h',
  },
]

const contactPage = () => (
  <Layout>
    <Seo title="Talk to us | Talk of the Town" />
    <Hero text="Talk to us" />
    <ExplanationSection
      imageBackground={require('../images/imagem_8.jpeg')}
      right
      className="contact-form-info"
    >
      <h3 className="title">Lorem ipsum dolor sit amet</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
        erat sapien, interdum eget tempus non, vehicula non lorem. Sed
        efficitur nunc erat, quis varius erat volutpat sed. Nulla
        facilisi. Nullam eleifend vestibulum velit in venenatis. Nullam
        pretium nulla at rutrum iaculis. Fusce tempor quis arcu sed
        molestie.
      </p>
    </ExplanationSection>
    <section className="contact-form">
      <Container>
        <Row>
          <Col lg="7">
            <Form onSubmit={handleSubmit}>
              <Input
                name="fullName"
                placeholder="Nome completo"
                className="form-control"
              />
              <Input
                name="email"
                placeholder="E-mail"
                type="email"
                className="form-control"
              />
              <Select
                defaultValue={selectOptions[0]}
                options={selectOptions}
                className="form-select"
              />
              <Input
                multiline
                name="message"
                placeholder="Mensagem"
                className="form-control"
              />
              <Button
                type="submit"
                className="button orange"
                color="link"
              >
                Enviar
              </Button>
            </Form>
          </Col>
          <Col lg={{ size: 4, offset: 1 }} className="sidebar">
            <div className="wrapper">
              <h3>Lorem ipsum dolor</h3>
              <div className="contact-info">
                <ul>
                  <li>(21) 98879-7689</li>
                  <li>(21) 98800-7789</li>
                  <li>
                      <a href="mailto:contato@talkofthetown.com.br">
                          contato@talkofthetown.com.br
                                        </a>
                    </li>
                </ul>
                <ul className="social">
                  <li>
                      <a
                          href="https://facebook.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                  fill="currentColor"
                                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                                />
                            </svg>
                        </a>
                    </li>
                  <li>
                      <a
                          href="https://instagram.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                  fill="currentColor"
                                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                />
                            </svg>
                        </a>
                    </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default contactPage
